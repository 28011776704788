<template>
  <el-main>
    <el-button style="margin-bottom: 15px" type="primary" size="small" @click="add">添加会员</el-button>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="会员名称：">
        <el-input size="small" v-model="member_name" placeholder="请输入会员名称"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="21px">
        <el-button size="small" type="primary" @click="search">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="member_name" label="会员名称" align="center"></el-table-column>
      <el-table-column prop="member_full_name" label="会员公司名称" align="center"></el-table-column>
      <el-table-column prop="tiktop_word" label="广告词" align="center"></el-table-column>
      <el-table-column prop="service_area" label="授权区域" align="center"></el-table-column>
      <el-table-column prop="service_year" label="授权年限" align="center">
        <template v-slot="{ row }">{{ row.service_year }}年</template>
      </el-table-column>
      <el-table-column prop="status" label="到期时间" align="center">
        <template v-slot="{ row }">
          {{ row.due_time | getDateformat }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="添加时间" align="center">
        <template v-slot="{ row }">
          {{ row.create_time | getDateformat }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="edit(row)" type="text" size="small">编辑</el-button>
          <el-button @click="del(row.id)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="添加/编辑会员" :visible.sync="dialogVisible" width="700px">
      <el-form label-width="135px" label-position="left" :model="addForm" :rules="rules" ref="form">
        <el-form-item label="会员名称：" prop="member_name" class="servicer-name">
          <el-input v-model="addForm.member_name"></el-input>
          <span>作为后台查找，前端不显示</span>
        </el-form-item>
        <el-form-item label="会员公司名称：" prop="member_full_name">
          <el-input v-model="addForm.member_full_name"></el-input>
        </el-form-item>
        <el-form-item label="授权区域：" prop="service_area">
          <el-input v-model="addForm.service_area"></el-input>
        </el-form-item>
        <el-form-item label="授权年限：" prop="service_year">
          <el-input v-model="addForm.service_year"></el-input>
        </el-form-item>
        <el-form-item label="广告词：" prop="tiktop_word">
          <el-input v-model="addForm.tiktop_word"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmForm">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      member_name: '',
      page: 1,
      rows: 10,
      list: [],
      total_number: 0,
      dialogVisible: !1,
      addForm: {
        member_name: '',
        member_full_name: '',
        service_area: '',
        service_year: '',
        tiktop_word:''
      },
      rules: {
        member_name: [{ required: true, message: '请输入会员名称', trigger: 'blur' }],
        member_full_name: [{ required: true, message: '请输入会员公司名称', trigger: 'blur' }],
        service_area: [{ required: true, message: '请输入授权区域', trigger: 'change' }],
        service_year: [{ required: true, message: '请输入授权年限', trigger: 'change' }],
        tiktop_word: [{ required: true, message: '请输入广告词', trigger: 'change' }],
      },
    };
  },
  filters: {
    getDateformat: getDateformat,
  },
  created() {
    this.getList();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    search() {
      this.page = 1;
      this.getList();
    },
    clearSearch() {
      this.page = 1;
      this.member_name = '';
      this.getList();
    },
    add() {
      this.addForm = {
        member_name: '',
        member_full_name: '',
        service_area: '',
        service_year: '',
        tiktop_word:''
      };
      this.dialogVisible = !0;
    },
    edit(row) {
      this.addForm.member_name = row.member_name;
      this.addForm.member_full_name = row.member_full_name;
      this.addForm.service_area = row.service_area;
      this.addForm.service_year = row.service_year;
      this.addForm.tiktop_word = row.tiktop_word;
      this.addForm.id = row.id;
      this.dialogVisible = !0;
    },
    del(id) {
      this.$confirm('请确定是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.OfficeWebsite.delMember, {
            id: id,
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.$message.success('删除成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    confirmForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.addForm.id ? this.$api.OfficeWebsite.editMember : this.$api.OfficeWebsite.addMember, this.addForm).then(res => {
            if (res.code == 0) {
              this.getList();
              this.dialogVisible = !1;
              this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getList() {
      this.$axios
        .post(this.$api.OfficeWebsite.memberList, {
          page: this.page,
          rows: this.rows,
          member_name: this.member_name,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  /deep/ .servicer-name .el-form-item__content {
    display: flex;
    .el-input {
      flex: 1;
    }
    span {
      color: #999;
      margin-left: 10px;
      font-size: 12px;
    }
  }
}
</style>
